import { default as React } from 'react';
import { fluidImage as fluidImageFragment } from '../queries/fragments';
import { get } from 'lodash';
import { graphql } from 'gatsby';
import styled from 'react-emotion';

import AnimatedImage from '../components/AnimatedImage';
import Container from '../components/Container';
import IndexLayout from '../layouts';
import Page from '../components/Page';
import SplitText from '../components/SplitText';

import { colors, fonts, widths } from '../styles/variables';

import { useWindowDimensions } from '../util/hooks';

const About = styled.div`
	padding: 30px;
`;

const LightningContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: auto;
	justify-content: space-between;
	margin: auto;
	width: 70%;

	@media only screen and (max-width: ${widths.md}px) {
		width: 100%;
	}
`;

const LightningText = styled.p`
	color: ${colors.accent};
	font-family: ${fonts.monospace};
	font-size: 32px;
	font-weight: 300;
	line-height: 42px;
	margin: 0 20px;
	text-transform: uppercase;
	text-align: center;
	width: 50px;
`;

const Header = styled.div`
	background-color: ${colors.background};
	padding: 30px 5px;
	position: sticky;
	top: 0px;
	z-index: 1;
`;

const PictureFrame = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	padding: 15px;

	@media screen only and (max-width: ${widths.md}px) {
		padding: 0;
	}
`;

const Text = styled.p`
	color: ${colors.light};
	font-family: ${fonts.sansSerif};
	font-size: 18px;
	font-weight: 300;
	padding: 5px 5px 5px 10%;
`;

const WORDS = [
	'React',
	'Javascript',
	'ES6',
	'Node.js',
	'Express',
	'Redux',
	'HTML',
	'CSS',
	'SASS',
	'Webpack',
	'D3',
	'React Native',
	'TypeScript',
	'Mocha',
	'Jest',
	'Docker',
	'ios',
	'Android',
	'AWS',
	'Selenium',
	'GraphQL',
	'MongoDB',
	'Angular',
	'Artist',
	'Engineer',
	'Wave',
	'Particle',
	'Writer',
	'Surfer',
	'Traveler',
	'Reader',
	'Runner',
	'Musician',
];

const spacedText = (text: string) => {
	return text
		.split('')
		.map((char: string) => `${char} `)
		.join('');
};

interface IndexProps {
	data: {
		profile: Node,
	};
}

const IndexPage: React.FunctionComponent<IndexProps> = ({ data }) => {
	const profileFluid = get(data, 'profile.childImageSharp.fluid');
	const { width } = useWindowDimensions();
	const profile = !!profileFluid && (
		<AnimatedImage
			containerWidth={width}
			fluid={profileFluid}
			index={0}
			width={width < widths.md ? 0.9 : 0.6}
		/>
	);
	return (
		<IndexLayout>
			<Page>
				<Container>
					<Header>
						<SplitText text="Andrew Gonzales" timeout={2000} />
					</Header>
					<LightningContainer>
						{WORDS.map((word, i) => {
							return (
								<div key={`${word}_${i}`}>
									<LightningText>{spacedText(word)} /</LightningText>
								</div>
							);
						})}
					</LightningContainer>

					<PictureFrame>{profile}</PictureFrame>
					<About>
						<Text>
							The soul of an artist paired with the mindset of an engineer. I am here
							to make the future, and software is the best medium I've found.
						</Text>
						<Text>
							By daylight, I write awesome front-end JavaScript for a confidential project at Amazon Web Services.
						</Text>
						<Text>
							On the side, I'm patiently working on my novel. When I can, I love to
							travel and surf, though I'm only good at one of them. Ask me about what
							I'm reading!
						</Text>
					</About>
				</Container>
			</Page>
		</IndexLayout>
	);
};

export default IndexPage;

export const fluidImage = fluidImageFragment;
export const pageQuery = graphql`
	query {
		profile: file(relativePath: { regex: "/travel/12_2016_Morocco/Morocco_H.jpg/" }) {
			...fluidImage
		}
	}
`;
