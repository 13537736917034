import { default as React, useEffect, useState } from 'react';
import styled from 'react-emotion';

import { colors, fonts, widths } from '../styles/variables';

import { useTimeout } from '../util/hooks';

const List = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	line-height: 1.2;
	text-align: center;
`;

interface ListItemProps {
	split: boolean;
}

const ListItem = styled.div`
	color: transparent;
	cursor: pointer;
	font-family: ${fonts.sansSerif};
	font-size: 52px;
	font-weight: 600;
	line-height: 42px;
	position: relative;
	text-transform: uppercase;
	text-align: center;

	// === Split Line =====
	&::before {
		background: ${colors.mainBrand};
		border-radius: 4px;
		content: '';
		display: block;
		height: 4px;
		left: -10%;
		margin-top: -2px;
		position: absolute;
		right: -10%;
		top: 49%;
		transform: scale(0);
		transition: transform 0.8s cubic-bezier(0.16, 1.08, 0.38, 0.98);
		z-index: 1;
	}

	${({ split }: ListItemProps) => {
		return split
			? `
			.Mask { color: #FFF; transform: skewX(12deg) translateX(5px); }
			.Mask + .Mask { transform: skewX(12deg) translateX(-5px); }
			&::before { transform: scale(1); }
		`
			: '';
	}}

	@media only screen and (max-width: ${widths.md}px) {
		font-size: 40px;
	}
`;

const Mask = styled.div`
	color: ${colors.lightAccent};
	display: block;
	height: 49%;
	overflow: hidden;
	position: absolute;
	top: 0;
	transition: all 0.8s cubic-bezier(0.16, 1.08, 0.38, 0.98);

	span {
		display: block;
	}
`;

const BottomMask = styled(Mask)`
	height: 51.1%;
	top: 48.9%;

	span {
		transform: translateY(-49%);
	}
`;

interface SplitProps {
	timeout?: number;
	text: string;
}

const DEFAULT_PROPS = {
	timeout: 500,
};

const SplitText: React.FunctionComponent<SplitProps> = props => {
	const [split, setSplit] = useState(false);
	useTimeout(
		() => {
			setSplit(false);
		},
		props.timeout,
		[split]
	);

	useEffect(() => {
		const splitHandler = () => setSplit(true);
		window.addEventListener('mousemove', splitHandler);
		window.addEventListener('touchmove', splitHandler);
		window.addEventListener('scroll', splitHandler);
		return () => {
			window.removeEventListener('mousemove', splitHandler);
			window.removeEventListener('touchmove', splitHandler);
			window.removeEventListener('scroll', splitHandler);
		};
	}, []);
	return (
		<List>
			<ListItem split={split}>
				{props.text}
				<Mask className="Mask">
					<span>{props.text}</span>
				</Mask>
				<BottomMask className="Mask">
					<span>{props.text}</span>
				</BottomMask>
			</ListItem>
		</List>
	);
};

SplitText.defaultProps = DEFAULT_PROPS;

export default SplitText;
